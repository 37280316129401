import React, { useState, useEffect } from "react"
import Layout from "templates/EventsEventGet/layout"
import Background from "components/background"

import SEO from 'components/seo'
import style from 'templates/EventsEventGet/EventsEventGet.module.scss'
import parse from 'html-react-parser';

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
		window.document && window.document.createElement)
);

const EventsEventGet = ({ data, pageContext }) => {
	const { edges } = data.allEventsEvent;
	const nodes = edges.map(({ node }) => node);
	let event = nodes[0];
	useEffect(() => {
		fetch(`${process.env.GATSBY_ZMS_API_URL}events/event/all/`)
			.then(response => response.json())
			.then(({ data }) => {
				if (canUseDOM) {
					data = data.filter((datum) => {
						if (datum.uri === window.location.pathname) return datum
					})
					if (data.length !== 0) event = data[0];
				}
			})
			.catch(err => console.log);
	}, [])

	if (!event) {
		return (
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className={`${style.nothingHere} cell small-12`}>
							<h1>Nothing to see here</h1>
							<p>The event that you are looking for has either moved or no longer exists.</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	const nth = function (d) {
		if (d > 3 & d < 21) return 'th';
		switch (d % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		}
	}
	var day = event.startDate ?
		new Date(event.startDate).toLocaleDateString('en-US', { weekday: 'long' }) :
		new Date(event.showDate).toLocaleDateString('en-US', { weekday: 'long' })

	var date = event.startDate ?
		new Date(event.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) :
		new Date(event.showDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
	const d = new Date(Date.parse(event.startDate));
	const dateString = `${monthNames[d.getMonth()]} ${d.getDate()}${nth(d.getDate())}, ${d.getFullYear()}`;
	const metaData = data.site.siteMetadata;
	const twitterHandle = metaData.twitterHandle;
	const title = event.name;
	var schema = {
		"@context": "http://schema.org",
		"@type": "BlogPosting",
		"headline": title,
		"datePublished": new Date(event.startDate).toDateString(),
		"mainEntityOfPage": "True",
		"publisher": {
			"@type": "Organization",
			"name": metaData.title,
		},
		"articleBody": event.body,
	}
	if (event.modified) {
		schema.datemodified = new Date(event.modified).toDateString();
	}
	var SEOimage;
	if (event.image) {
		schema.image = metaData.siteUrl + event.image;
		SEOimage = metaData.siteUrl + event.image;
	}
	return (
		<>
			<Layout link="/events/" image={event.image} goBackText="Back to All Events" enableDanosFoundation={false} enableFooterTabs={true}>
				<section>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 medium-8 medium-offset-2">
								<div className={style.innerContent}>
									<div className={style.title}>{event.name}</div>
									{/* <p><strong>Date:</strong> {day}, {date}</p> */}
									<p><strong>Date:</strong> {event.time}</p>
									<p><strong>Address:</strong> {event.street ? event.street + "," : ""} {event.suite ? event.suite : ""} {event.city}, {event.state} {event.zip}</p>
									{event.contactName && <p><strong>Contact Information:</strong> {event.contactName} | {event.contactInfo}</p>}
									<div className={style.description}>{parse((event.description ? event.description : ""))}</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
			<SEO title={event ? event.title : ''} image={SEOimage} schema={schema} />
		</>
	)
}

export default EventsEventGet

export const query = graphql`
	query ($uri: String, $lang: String) {
		allEventsEvent(filter: {uri: {eq: $uri}, lang: {eq: $lang}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
			  node {
				name
				permalink
				state
				suite
				street
				summary
				time
				uri
				zip
				image
				externalLinkText
				externalLink
				description
				city
				contactInfo
				contactName
				calendarLink
				created
				showDate
				showInFeed
				startDate
				modified
				_model
			  }
			}
		  }
		site {
			siteMetadata {
				siteUrl
				twitterHandle
				title
				author
			}
		}
	}
`